import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"


const Contact = ({data}) => {
	return (
		<div className="pageWrapper page">
            <SEO 
                title="Privacy policy" 
                description="Privacy policy for use at Dinchat"
                pagePath="/privacy"
            />

			<h1>Privacy policy</h1>
            <div className="dividerHr">
                <span className="dividerSpan"></span>
            </div>

            <div className="termsPage">
    			<h1> Välkommen till vår sekretesspolicy </h1>
                <h3> Din integritet är avgörande för oss. </h3>

                Det är vibbli.se's policy att respektera din integritet när det gäller all information vi kan samla in
                medan du använder vår hemsida. Vi respekterar din integritet och har för avsikt att skydda personuppgifter som du kan ge oss via webbplatsen. Vi har antagit denna sekretesspolicy ("Sekretesspolicy") för att förklara vilken information som kan hämtas på vår hemsida, hur vi använder den här informationen och under vilka omständigheter vi kan avslöja informationen till tredje part. Denna sekretesspolicy gäller endast information som vi samlar in via webbplatsen och gäller inte vår insamling av information från andra källor. 
                 Denna sekretesspolicy, tillsammans med de Villkor som anges på vår hemsida, anger de allmänna reglerna och reglerna för din användning av vår webbplats. Beroende på dina aktiviteter när du besöker vår webbplats kan du behöva godkänna ytterligare villkor. 

                 Vi använder dina data för att tillhandahålla och förbättra tjänsten. Genom att använda Tjänsten godkänner du
                insamling och användning av information i enlighet med denna policy. 

                <h2> Definitioner </h2>
                <h3> Tjänsten </h3>
                 Tjänst betyder webbplatsen https://vibbli.se 

                <h3> Personuppgifter </h3>
                 Personuppgifter betyder data om en levande individ som kan identifieras från
                dessa uppgifter (eller från dessa och annan information, antingen i vår besittning eller sannolikt
                att komma in i vår egendom). 

                <h3> Användningsdata </h3>
                 Användningsdata är data som samlas automatiskt antingen genereras av användningen av vår
                Service eller från själva serviceinfrastrukturen (till exempel längden på ett sidbesök). 


                <h2> Informationsinsamling och användning </h2>
                 Vi samlar flera olika typer av information för olika ändamål för att tillhandahålla och
                förbättra vår service till dig.

                <h3> Typer av data som samlas </h3>
                <b> Personliga data </b>
                 När vi använder vår tjänst kan vi fråga dig att ge oss vissa personliga identifierbara uppgifter
                Information som kan användas för att kontakta eller identifiera dig ("Personuppgifter").
                Personligt identifierbar information kan inkludera, men är inte begränsad till:
                <i> E-postadress </i>
                <i> Förnamn och efternamn </i>
                <i> Cookies och användningsdata </i>
                

                 Vi kan använda dina personuppgifter för att kontakta dig med nyhetsbrev, marknadsföring eller marknadsföring
                material och annan information som kan vara av intresse för dig.
                Du kan välja bort att ta emot några eller alla av dessa meddelanden från oss av
                Följ anknytningslänken eller anvisningarna i något e-postmeddelande som vi skickar eller genom att kontakta oss.
                

                <b> Användningsdata </b>
                 Vi kan också samla in information som din webbläsare skickar när du besöker vår tjänst eller
                när du kommer åt tjänsten via eller via en mobilenhet ("användningsdata").
                Denna användningsdata kan innehålla information som datorns Internet Protocol-adress
                (t ex IP-adress), webbläsartyp, webbläsareversion, sidorna i vår tjänst som du besöker,
                Tid och datum för ditt besök, den tid som spenderas på dessa sidor, unika identifieringsenheter
                och andra diagnostiska data. 
                 När du använder tjänsten via eller via en mobilenhet kan den här användningsdata innehålla
                information som typen av mobilenhet du använder, ditt unika ID för mobilenheten,
                IP-adressen till din mobilenhet, ditt mobila operativsystem, typen av mobil
                Webbläsare du använder, unika enhetsidentifierare och annan diagnostisk data. 

                <h2> Användning av data </h2>
                 vibbli.se använder de samlade uppgifterna för olika ändamål: 
                 Att tillhandahålla och behålla vår tjänst 
                 För att meddela dig om ändringar i vår tjänst 
                 För att du ska kunna delta i interaktiva funktioner i vår tjänst när du väljer att göra det 
                 För att ge kundsupport 
                 Samla analys eller värdefull information så att vi kan förbättra vår service 
                 För att övervaka användningen av vår tjänst 
                 Att upptäcka, förebygga och ta itu med tekniska problem 
                 För att ge dig nyheter, specialerbjudanden och allmän information om andra varor,
                tjänster och evenemang som vi erbjuder som liknar dem som du redan har köpt eller
                frågade om inte du har valt att inte ta emot sådan information 
                 Rättslig grund för behandling av personuppgifter enligt Allmänna databeskyddsförordningen (GDPR) 

                 vibbli.se kan behandla dina personuppgifter på grund av att: 
                 Vi behöver göra ett avtal med dig 
                 Du har gett oss tillåtelse att göra det 
                 Bearbetningen är i våra legitima intressen och det överträder inte dina rättigheter 
                 Att följa lagen 

                <h2> Lagring av Data </h2>
                 vibbli.se behåller dina personuppgifter endast så länge som behövs för
                ändamål som anges i denna sekretesspolicy. Vi kommer behålla och använda dina personuppgifter i den
                omfattning som krävs för att uppfylla våra lagliga skyldigheter (till exempel om vi är skyldiga
                att behålla dina uppgifter för att följa gällande lagar), lösa tekniska problem, tillhandahålla
                denna tjänst, lösa tvister och tillämpa våra juridiska avtal och villkor. 

                Användargenererad data som görs allmänt tillgänglig av användare kommer att lagras i 24 timmar. 
                Detta gäller exempelvis meddelanden postade i allmänna rum och kanaler på webbplatsen.
                Och användargenererad data som inte är allmänt tillgänglig, kommer att lagras tills dess att användaren
                önskar att radera sitt konto, då kommer all data associerat med kontot att raderas. Exempel på 
                data som inte är allmänt tillgänglig är meddelanden som sänds privat emellan användare eller i privata 
                rum och kanaler på webbplatsen.

                Vi genomför regelbundna kontroller av all data för att säkerställa att data som inte 
                används längre raderas, detta görs för att garantera att data inte lagras längre än nödvändigt. 
                Dessa kontroller utförs minst en gång per år.
                            


                <h2> Överföring av data </h2>
                 Din information, inklusive personuppgifter, kan överföras till - och underhållas på -
                datorer som ligger utanför din stat, provins, land eller annan statlig jurisdiktion
                där lagar om dataskydd kan skilja sig från de som gäller för din jurisdiktion.
                Ditt samtycke till denna sekretesspolicy följt av din inlämning av sådan information representerar din
                överenskommelse om överföringen. vibbli.se kommer att vidta alla åtgärder som är rimligt nödvändiga för att säkerställa
                att dina uppgifter behandlas säkert och i enlighet med denna sekretesspolicy och ingen överföring
                av dina personuppgifter kommer att ske till en organisation eller ett land om inte det finns
                är tillräckliga kontroller på plats, inklusive säkerheten för dina uppgifter och annan personlig information. 

                <h2> Juridiska krav </h2>
                <div> vibbli.se kan dela med sig av dina personuppgifter om det i god tro anses vara nödvändigt för att:

                <i> Följa en laglig skyldighet </i>
                <i> För att skydda och försvara vibbli.ses rättigheter eller egendom. </i>
                <i> För att förhindra eller undersöka eventuella felaktigheter i samband med tjänsten. </i>
                <i> För att skydda den personliga säkerheten för användare av tjänsten eller allmänheten </i>
                <i> För att skydda mot rättsligt ansvar </i>
                </div>


                <h2> Dina skydd för personuppgifter enligt GDPR (General Data Protection Regulation) </h2>
                 Om du är bosatt i Europeiska ekonomiska samarbetsområdet (EES) har du vissa uppgifter om personuppgifter.
                vibbli.se har som mål att vidta rimliga åtgärder för att du ska kunna korrigera, ändra, radera eller begränsa användningen
                av dina personuppgifter. 

                 Om du vill bli informerad om vilka personuppgifter vi har om dig och om du vill ha det
                tas bort från våra system, kontakta oss. 

                 Under vissa omständigheter har du följande personuppgifter: 
                 Rätten att komma åt, uppdatera eller ta bort den information vi har på dig.
                När det är möjligt kan du komma åt, uppdatera eller begära att du raderar dina personuppgifter
                direkt i avsnittet om kontoinställningar. Om du inte kan utföra dessa åtgärder själv,
                vänligen kontakta oss för att hjälpa dig. 

                 Rätten till rättelse. Du har rätt att få din information rättad om det
                Informationen är felaktig eller ofullständig. 

                 Rätten att invända. Du har rätt att invända mot vår behandling av dina personuppgifter. 

                 Begränsning av rätten. Du har rätt att begära att vi begränsar behandlingen av
                Din personliga information. 

                 Rätten till dataöverförbarhet. Du har rätt att få en kopia av informationen
                Vi har på dig i ett strukturerat, maskinläsbart och vanligt använd format. 

                 Rätten att återkalla samtycke. Du har också rätt att återkalla ditt samtycke när som helst där
                vibbli.se åberopade ditt samtycke till att behandla din personliga information. 

                 Observera att vi kan be dig att verifiera din identitet innan du svarar på sådana förfrågningar. 

                 Du har rätt att klaga till en dataskyddsmyndighet om vår samling och användning av
                dina personuppgifter För mer information kontakta din lokala dataskyddsmyndighet
                inom Europeiska ekonomiska samarbetsområdet (EES). 

                <h2>Serviceleverantörer </h2>
                 Vi kan använda tredjepartsföretag och privatpersoner för att underlätta vår Service ("Service Providers"),
                att tillhandahålla Tjänsten på våra vägnar, att utföra servicerelaterade tjänster eller att hjälpa oss i
                analyserar hur vår Service används. 
                 Dessa tredje parter har endast tillgång till dina personuppgifter för att utföra dessa uppgifter på vår vägnar
                och är skyldig att inte avslöja eller använda det för något annat syfte. 

                <h2> Analytics </h2>
                 Vi kan använda tredjepartsleverantörer för att övervaka och analysera användningen av vår tjänst. En sådan tjänst
                är Google Analytics. Google Analytics är en webbanalystjänst som erbjuds av Google som spårar och
                rapporterar webbplats trafik. Google använder de samlade uppgifterna för att spåra och övervaka användningen av vår tjänst.
                Dessa data delas med andra Google-tjänster. Google kan använda den samlade data för att kontextualisera
                och personifiera annonserna i sitt eget annonsnätverk.
                För mer information om Googles privatlivspraxis,
                Besök Googles webbplats för sekretess och villkor: https://policies.google.com/privacy?hl=sv 



                <h2> Besökare på webbplatsen </h2>
                 Liksom de flesta webbplatsoperatörer samlar vibbli.se icke-personligt identifierande information
                av det slag som webbläsare och servrar brukar göra tillgängliga,
                såsom webbläsartyp, språkinställningar, referensplats,
                och datum och tid för varje besökareförfrågan.
                vibbli.se's syfte att samla in personligt identifierande information är att
                bättre förstå hur vibbli.se besökare använder sin webbplats.
                Från tid till annan kan vibbli.se släppa ut icke-personligt identifierande uppgifter i
                aggregat, t ex genom att publicera en rapport om trender i användningen av sin webbplats. 
                 vibbli.se samlar också potentiellt personligt identifierande information som
                Internet Protocol (IP) adresser. vibbli.se avslöjar endast inloggad användare och kommenterare
                IP-adresser under samma omständigheter som den använder och beskriver personligt identifierande
                information som beskrivs nedan. 

                <h2> Barnens integritet </h2>
                 Vår tjänst tar inte emot någon under 18 år. 
                 Vi samlar inte medvetet personligt identifierbar information från någon under 18 år.
                Om du är förälder eller vårdnadshavare och du är medveten om att ditt barn har gett oss Personuppgifter, var god kontakta oss. Om vi blir medvetna om att vi har samlat in personuppgifter från barn utan verifikation av föräldrars medgivande, tar vi åtgärder för att ta bort den informationen från våra servrar. 

                <h2> Säkerhet </h2>
                 Säkerheten för din personliga information är viktig för oss, men kom ihåg att ingen överföringsmetod över Internet, eller elektronisk lagringsmetod är 100% säker. Medan vi strävar efter att använda kommersiellt acceptabla medel för att skydda din personliga information, kan vi inte garantera den absoluta säkerheten. 

                 Signaler inte spåras under Kalifornien Online Privacy Protection Act (CalOPPA): 
                 Vi stöder inte Spåra inte ("DNT"). Spåra inte är en preferens du kan ställa in på din webbsida
                webbläsare för att informera webbplatser som du inte vill spåras. 

                <h2> Annonser </h2>
                 Annonser som visas på vår webbplats kan levereras till användare av annonseringspartners, som kan ställa in cookies. Dessa cookies tillåter annonsservern att känna igen din dator varje gång de skickar dig en onlineannonsering för att sammanställa information om dig eller andra som använder din dator. Den här informationen tillåter annonsnätverk att bland annat leverera riktade annonser som de tror är av största intresse för dig. Denna sekretesspolicy täcker användningen av cookies av vibbli.se och täcker inte användningen av cookies av någon annonsör. 


                <h2> Länkar till andra webbplatser </h2>
                 Vår tjänst kan innehålla länkar till externa webbplatser som inte drivs av oss. Om du klickar
                På en tredjeparts länk kommer du att styras till den tredje partens webbplats. Vi rekommenderar starkt dig
                att läsa igenom sekretesspolicyen och villkoren för varje webbplats du besöker. 
                 Vi har ingen kontroll över och tar inget ansvar för innehållet,
                sekretesspolicy eller praxis från tredje parts webbplatser, produkter eller tjänster. 


                <h2> Samlad statistik </h2>
                 vibbli.se kan samla in statistik om besökarnas beteende på sin webbplats. vibbli.se kan visa denna information offentligt eller ge den till andra. Men, vibbli.se avslöjar inte din personligt identifierande information. 


                <h2> Spårning och cookies </h2>
                 För att berika och förbättra din online-upplevelse använder vibbli.se "Cookies",
                liknande tekniker och tjänster som tillhandahålls av andra för att visa personligt innehåll,
                lämplig annonsering och lagra dina inställningar på din dator. 
                 En cookie är en sträng information som en webbplats lagrar på en besökares dator,
                och att besökarens webbläsare tillhandahåller webbplatsen varje gång besökaren returnerar.
                vibbli.se använder cookies för att hjälpa vibbli.se identifiera och spåra besökare,
                deras användning av Tjänsten och deras hemsida åtkomstpreferenser.
                vibbli.se besökare som inte vill ha cookies placerade på sina datorer bör ställa in
                deras webbläsare för att vägra cookies innan de använder vibbli.ses webbplatser, med nackdelen att vissa funktioner på vibbli.ses webbplatser kanske inte fungerar korrekt utan hjälp av cookies. 
                 Genom att fortsätta navigera på vår webbplats utan att ändra dina cookieinställningar,
                Du bekräftar härmed och godkänner vibbli.ses användning av cookies. 

                 Exempel på cookies vi använder:
                <i> Sessionkakor. Vi använder sessionskakor för att driva vår service. </i>
                <i> Preference Cookies. Vi använder Preference Cookies för att komma ihåg dina inställningar och olika inställningar. </i>
                <i> Säkerhetskakor. Vi använder säkerhetskakor av säkerhetsskäl </i>
                

                <h2> Ändringar i sekretesspolicy </h2>
                 Även om de flesta ändringar sannolikt kommer att vara mindre kan vibbli.se ändra sin integritetspolicy
                från tid till annan och i vibbli.ses eget gottfinnande. vibbli.se uppmuntrar besökare till
                Kolla ofta denna sida för eventuella ändringar i sin integritetspolicy. Din fortsatta användning av denna webbplats
                Efter ändringar i denna sekretesspolicy utgör din acceptans av sådan ändring. 

                <h2> Kontakta oss </h2>
                <p>
                 Om du har några frågor om denna sekretesspolicy, kontakta oss via e-post: support@vibbli.se 
                 </p>

                 <br />
            </div>
		</div>
	)
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`